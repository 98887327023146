import InputText from "@components/library_old/InputText";
import { ExclamationCircleIcon, PencilIcon } from "@heroicons/react/outline";
import { Select, Switch, Tooltip } from "antd";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import Debug from "components/atoms/Debug";
import { useField, useFormikContext } from "formik";
import { classNames, ConditionalWrapper, ObjectLiteral } from "lib/utils";
import React, { FormEventHandler, forwardRef, InputHTMLAttributes, TextareaHTMLAttributes, useRef } from "react";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  corner?: React.ReactNode;
  placeholder?: string;
  type?: string;
  error?: React.ReactNode;
  disabled?: boolean;
  handleSubmit?: FormEventHandler<HTMLFormElement>;
  addOnLeft?: React.ReactNode;
}

const TextInputWithRef = ({ name, label, corner, placeholder, type, error, disabled, handleSubmit, addOnLeft, ...props }: TextInputProps, ref) => {
  function handleKeyDown(e) {
    if (handleSubmit && e.key === "Enter" && e.metaKey) {
      handleSubmit(e);
    }
  }
  return (
    <>
      {(label?.length || corner) && (
        <div className={"flex justify-between"}>
          <label htmlFor={name} className={`block text-13-14 font-bold text-gray-700`}>
            {label}
          </label>
          <span className="text-13-14 text-gray-700">{corner}</span>
        </div>
      )}
      <div className="relative">
        <div className="relative mt-[11px] rounded shadow-sm">
          {/* // TODO: potentially supply a ref here ( ref={register && register({ required })} and get rid of Input component */}
          {/* TODO: the addOnLeft component */}

          <input
            name={name}
            type={type || "text"}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            className={classNames(
              "block w-full rounded text-13-16 transition-all",
              error
                ? "text-red-900 border-red-300 pr-40 placeholder-red-400 focus:border-red-300 focus:focus:ring focus:ring-red-200 focus:ring-opacity-50"
                : " border border-gray-400 placeholder-gray-500 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50", // TODO: Decide on fix for clipped ring outline.
              disabled && "bg-gray-100 text-gray-500"
            )}
            {...props}
            aria-invalid={!!error}
            aria-describedby={`${name}-error`}
            // Added this ref, but not sure if it's needed.
            ref={ref}
          />
        </div>

        <p
          className={classNames(
            "duration-400 mt-4 overflow-hidden text-12-14 text-red-600 transition-all",
            error ? "max-h-200 animate-fade-in-down" : "max-h-[0px] animate-fade-out-up"
          )}
          id={`${name}-error`}
        >
          {error}
          {/* Needed so the error box doesn't instantly collapse */}
          &nbsp;
        </p>
      </div>
    </>
  );
};

export const TextInput = forwardRef(TextInputWithRef);

interface FormikTextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  corner?: React.ReactNode;
  placeholder?: string;
  type?: string;
  className?: string;
  autoComplete?: string;
  handleSubmit?: FormEventHandler<HTMLFormElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  addOnLeft?: React.ReactNode;
}

export const FormikTextInput = ({
  name,
  label,
  corner,
  placeholder,
  type,
  className,
  autoComplete,
  handleSubmit,
  onChange,
  addOnLeft,
  ...rest
}: FormikTextInputProps) => {
  // field: An object containing onChange, onBlur, name, and value of the field (see FieldInputProps)
  const [field, meta] = useField(name);
  const error = meta.touched && meta.error;

  return (
    <div className={className}>
      <TextInput
        label={label}
        corner={corner}
        placeholder={placeholder}
        type={type}
        error={error}
        handleSubmit={handleSubmit}
        addOnLeft={addOnLeft}
        {...rest}
        {...field}
        onChange={(e) => {
          field.onChange(e);
          onChange && onChange(e);
        }}
      />
    </div>
  );
};

interface FormikUnstyledProps {
  label: string;
  id: string;
  name: string;
  className: string;
  placeholder?: string;
  corner?: string;
  remainingProps: ObjectLiteral;
}

export const FormikUnstyled = ({ label, id, name, className, placeholder, corner, ...remainingProps }: FormikUnstyledProps) => {
  const inputRef = useRef(null);
  const [field, meta] = useField(name);
  const error = meta.touched && meta.error;
  return (
    <>
      {Boolean(corner) && (
        <div className={`flex justify-between`}>
          <span className="text-sm leading-5 text-gray-600">{corner}</span>
        </div>
      )}

      <div className="group flex items-baseline">
        <ConditionalWrapper
          condition={error}
          wrapper={(children) => (
            <Tooltip placement="left" visible color="#f05252" title={error}>
              {children}
            </Tooltip>
          )}
        >
          <input
            ref={inputRef}
            placeholder={placeholder}
            {...field}
            {...remainingProps}
            className={classNames(
              "transition-default block w-full rounded px-8 py-6",
              error
                ? "text-red-900 border-red-300 bg-red-100 placeholder-red-500 focus:border-red-300 focus:ring-red"
                : "placeholder-gray-500 focus:border-blue-300 focus:outline-none focus:ring-blue group-hover:border-blue-300 group-hover:ring-blue",
              classNames
            )}
            aria-invalid={!!error}
          />
        </ConditionalWrapper>
        <Tooltip placement="right" title={`${label || name} ${(label || name) && " - "}click to edit`}>
          <PencilIcon
            className="ml-8 h-20 w-20 text-gray-300 group-hover:text-gray-700"
            onClick={() => {
              inputRef && inputRef.current.focus();
            }}
          />
        </Tooltip>
      </div>
      {error && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-12">
          <ExclamationCircleIcon className="h-20 w-20 text-red-600" />
        </div>
      )}

      {/* <Debug vars={{ field }} /> */}
    </>
  );
};

export const FormikTagInput = (props: { label: string; id: string; name: string; className?: string; corner?: string; props?: ObjectLiteral }) => {
  const [field, meta, helpers] = useField(props.name);
  const error = meta.touched && meta.error;
  const id = props.id || props.name;
  const formikProps = useFormikContext();
  return (
    <>
      <div className={`flex justify-between ${props.className}`}>
        <label htmlFor={id} className="block text-sm font-bold leading-5 text-gray-600">
          {props.label}
        </label>
        <span className="text-sm leading-5 text-gray-600">{props.corner}</span>
      </div>
      <div className="relative mt-4 w-full rounded shadow-sm">
        <Select
          id={id}
          mode="tags"
          value={field.value}
          onChange={(value, option) => {
            formikProps.setFieldValue(field.name, value);
          }}
          placeholder="swag"
          onBlur={(value) => {
            formikProps.setFieldTouched(props.name);
          }}
          {...props}
        />

        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-12">
            <ExclamationCircleIcon className="h-20 w-20 text-red-600" />
          </div>
        )}
      </div>
      {error ? (
        <p className="mt-8 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      ) : null}
      {/* <Debug vars={{ field, meta, props }} /> */}
    </>
  );
};

interface FormikTextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  id?: string;
  label?: string;
  type?: string;
  corner?: string;
  onSubmit?: FormEventHandler<HTMLTextAreaElement>;
  value?: string;
  className?: string;
}

export const FormikTextarea = ({ name, id, label, type, corner, onSubmit, value, className, ...rest }: FormikTextareaProps) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(name);
  const error = meta.touched && meta.error;
  const inputId = id || name;

  function handleKeyDown(e) {
    if (onSubmit && e.key === "Enter" && e.metaKey) {
      onSubmit(e);
    }
  }

  return (
    <>
      <div className={`flex justify-between`}>
        <label htmlFor={inputId} className="block text-sm font-bold leading-5 text-gray-600">
          {label}
        </label>
        <span className="text-sm leading-5 text-gray-600">{corner}</span>
      </div>

      <div className="relative mt-4 rounded">
        <textarea
          value={value}
          {...field}
          {...rest}
          onKeyDown={handleKeyDown}
          className={classNames(
            error
              ? "text-red-900 form-input block w-full border-red-300 pr-40 placeholder-red-300 focus:border-red-300 focus:ring-red sm:text-sm sm:leading-5"
              : "transition-default block w-full appearance-none rounded border border-gray-300 px-12 py-8 placeholder-gray-600 focus:border-blue-300 focus:outline-none focus:ring-blue sm:text-sm sm:leading-5",
            className
          )}
          aria-invalid={!!error}
          aria-describedby={`${inputId}-error`}
        />
      </div>
      {error ? (
        <p className="mt-8 flex gap-8 text-sm text-red-600" id={`${inputId}-error`}>
          {error}
        </p>
      ) : null}
    </>
  );
};

export const FormikRange = ({ label, getValue = (val) => val, onChange, multiple = false, ...props }) => {
  const [field, meta, helpers] = useField(props.name);

  const error = meta.touched && meta.error;
  const id = props.id || props.name;

  return (
    <div className={props.className}>
      <label htmlFor={id} className="block text-sm font-bold leading-5 text-gray-700">
        {label}
      </label>

      <select
        {...field}
        onChange={(e) => {
          onChange ? onChange(e.target.value) : helpers.setValue(e.target.value);
        }}
        multiple={multiple}
        className="form-select mt-4 block w-full border-gray-400 py-8 pl-12 pr-40 text-base leading-6 focus:border-blue-300 focus:outline-none focus:ring-blue sm:text-sm sm:leading-5"
      >
        {props.children}
      </select>
      {error ? (
        <p className="mt-8 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      ) : null}
      <Debug vars={{ field }} />
    </div>
  );
};

export const FormikSelect = ({ label, onChange, multiple = false, ...props }) => {
  const [field, meta, helpers] = useField(props.name);

  const error = meta.touched && meta.error;
  const id = props.id || props.name;

  return (
    <div className={props.className}>
      <label htmlFor={id} className="text-smfont-bold block leading-5 text-gray-700">
        {label}
      </label>

      <select
        {...field}
        onChange={(e) => {
          onChange ? onChange(e.target.value) : helpers.setValue(e.target.value);
        }}
        multiple={multiple}
        className="form-select mt-4 block w-full rounded border-gray-400 py-8 pl-12 pr-40 text-base leading-6 focus:border-blue-300 focus:outline-none focus:ring-blue sm:text-sm sm:leading-5"
      >
        {props.children}
      </select>
      {error ? (
        <p className="mt-8 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      ) : null}
      {/* <Debug vars={{ field }} /> */}
    </div>
  );
};

interface FormikSwitchProps {
  name: string;
  label: React.ReactNode;
  onChange: SwitchChangeEventHandler;
}

export const FormikSwitch = (props: FormikSwitchProps) => {
  const [field, meta, helpers] = useField(props.name);
  const error = meta.touched && meta.error;

  return (
    <div className="mt-16 flex items-center">
      <Switch className="mr-8 flex-none" size="small" {...props} onChange={props.onChange} />
      <div className="flex-1">{props.label}</div>
    </div>
  );
};
